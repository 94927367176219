<template>
  <div :class="['page', { isSwitchOld }]">
    <div class="box">
      <div class="inBox" @click="typeShow = true">
        <div class="type">预约类型</div>
        <div class="text">{{ form.appointmentType }}</div>
        <img src="./img/arrow.png" class="arrow" />
      </div>
      <div class="line"></div>
    </div>
    <div
      class="box"
      @click="showCommunity()"
      v-if="form.appointmentType == '团队预约'"
    >
      <div class="inBox">
        <div class="type">添加社群</div>
        <div class="text">{{ form.community }}</div>
        <img src="./img/arrow.png" class="arrow" />
      </div>
      <div class="line"></div>
    </div>
    <div class="box">
      <div class="inBox">
        <div class="type">参与人数</div>
        <div class="numBox">
          <img src="./img/sub.png" class="sub" @click="numberChange('sub')" />
          <div class="num">{{ form.partakeNums }}</div>
          <img src="./img/add.png" class="add" @click="numberChange('add')" />
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div class="explain">预约说明</div>
    <van-field
      v-model="form.message"
      rows="6"
      type="textarea"
      placeholder="请填写预约理由"
    />
    <div class="btnBox">
      <div class="btn" @click="submit()">立即预约</div>
    </div>
    <v-picker
      :columns="typeColumns"
      :valueShow="typeShow"
      :valueKey="typeKey"
      @clickOverlay="clickTypeOverlay"
      @cancel="cancelTypePick"
      @confirmPicker="confirmType"
    ></v-picker>
    <van-popup
      v-model="communityShow"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <div class="popup_box">
        <div class="top_box">
          <div class="cancel" @click="cancelTeamPick()">取消</div>
          <div class="complete" @click="confirmTeam()">确认</div>
        </div>
        <div class="input_box">
          <input
            type="text"
            onkeyup="this.value=this.value.replace(/[, ]/g,'')"
            v-model="keyWord"
            placeholder="搜索社群昵称"
            v-on:input="changeUserTeam()"
          />
        </div>
        <div class="bottom_box">
          <div
            :class="['item', { active: item.state == 1 }]"
            v-for="(item, index) in userTeamInfoList"
            :key="index"
          >
            <div @click="teamChoice(item)">
              {{ item.associationName }}
            </div>
          </div>
          <div class="no_more" v-if="userTeamInfoList.length == 0">
            没有更多了
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { appointmentTypeList } from "./map.js";
import { userTeamInfoUrl } from "./api.js";
import { Toast } from "vant";

export default {
  name: "apointmentContent",
  data() {
    return {
      timer: null,
      placeMaxNum: null, //参与人数最大上限
      nowTeamObj: {}, //当前选择的社群
      supportOrderType: "", //预约类型
      typeKey: "text",
      typeColumns: appointmentTypeList,
      keyWord: "", // 搜索社群关键字
      typeShow: false, //预约类型
      communityShow: false, //社群类型
      form: {
        appointmentType: "选择预约类型",
        community: "选择社群",
        partakeNums: 1, //参与人数
        message: "", // 预约说明
      },
      userTeamInfoList: [], //用户加入社群列表
      placeId: "", //场地id
      placeName: "", //场地名称
      isSwitchOld: false,
    };
  },
  computed: {
    userId() {
      //当前用户ID
      return this.$store.state.userId;
    },
  },
  created() {
    if (localStorage.getItem("isSwitchOld") != null) {
      this.isSwitchOld = JSON.parse(localStorage.getItem("isSwitchOld"));
    }
    this.placeId = this.$route.query.placeId;
    this.placeName = this.$route.query.placeName;
    this.placeMaxNum = this.$route.query.count;
    if (this.$route.query.supportOrderType == "1") {
      this.typeColumns = [
        {
          text: "个人预约",
        },
      ];
    } else if (this.$route.query.supportOrderType == "2") {
      this.typeColumns = [
        {
          text: "团队预约",
        },
      ];
    }
    this.getUserTeamList();
  },
  methods: {
    submit() {
      //立即预约按钮
      if (this.form.appointmentType == "选择预约类型") {
        Toast("请选择预约类型！");
        return;
      }
      if (
        this.form.appointmentType == "团队预约" &&
        this.form.community == "选择社群"
      ) {
        Toast("请选择社群！");
        return;
      }
      if (this.form.message == "") {
        Toast("请填写预约理由！");
        return;
      }
      // if (this.form.partakeNums > this.placeMaxNum) {
      //     Toast('参与人数大于场地可容纳人数上限！')
      //     return
      // }
      this.$router.push({
        name: "areaSession",
        query: {
          associationId: this.nowTeamObj.id,
          placeId: this.placeId,
          placeName: this.placeName,
          count: this.form.partakeNums,
          supportOrderType: this.filter(this.form.appointmentType),
          orderAssociationName: this.form.community,
          remark: this.form.message,
        },
      });
    },
    filter(str) {
      if (str == "个人预约") {
        return 1;
      } else if (str == "团队预约") {
        return 2;
      }
    },
    changeUserTeam() {
      // 搜索社群名称input事件
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        let params = {
          userId: this.userId,
          keyWord: this.keyWord,
        };
        this.$axios
          .get(`${userTeamInfoUrl}`, {
            params: params,
          })
          .then((res) => {
            if (res.code === 200) {
              res.data.forEach((item, index) => {
                item.state = 0;
              });
              this.userTeamInfoList = res.data;
            }
          });
      }, 500);
    },
    teamChoice(data) {
      //社群item点击事件
      this.userTeamInfoList.forEach((item, index) => {
        item.state = 0;
      });
      data.state = 1;
      this.nowTeamObj = data;
    },
    getUserTeamList() {
      //获取用户已经加入社群列表
      let params = {
        userId: this.userId,
        keyWord: this.keyWord,
      };
      this.$axios
        .get(`${userTeamInfoUrl}`, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            res.data.forEach((item, index) => {
              item.state = 0;
            });
            this.userTeamInfoList = res.data;
          }
        });
    },
    numberChange(type) {
      //参与人数变动
      if (type == "sub") {
        if (this.form.appointmentType == "个人预约") {
          if (this.form.partakeNums == 1) {
            this.form.partakeNums = 1;
          } else {
            this.form.partakeNums--;
          }
        } else {
        }
      } else if (type == "add") {
        if (this.form.appointmentType == "个人预约") {
          if (this.form.partakeNums == this.placeMaxNum) {
            this.form.partakeNums == this.placeMaxNum;
          } else if (this.placeMaxNum == 0) {
            this.form.partakeNums++;
          } else {
            this.form.partakeNums++;
          }
        } else {
        }
      }
    },
    showCommunity() {
      //拉起选择社群遮罩层
      if (
        this.userTeamInfoList.length === 0 &&
        this.form.appointmentType == "团队预约"
      ) {
        Toast(`您还未加入社群\n请重新选择预约类型`);
      } else {
        this.communityShow = true;
      }
    },
    cancelTeamPick() {
      //选择社群弹出层取消按钮
      this.communityShow = false;
    },
    confirmTeam() {
      //选择社群弹出层确认按钮
      console.log(this.nowTeamObj);
      this.form.community = this.nowTeamObj.associationName;
      this.form.partakeNums = this.nowTeamObj.memberNum;
      this.communityShow = false;
    },
    cancelTypePick() {
      //选择预约类型弹出层取消按钮
      this.typeShow = false;
    },
    confirmType(value) {
      //选择预约类型弹出层确认按钮
      console.log(value.text);
      this.form.appointmentType = value.text;
      if (this.form.appointmentType == "个人预约") {
        this.form.partakeNums = 1;
      } else if (this.form.appointmentType == "团队预约") {
        this.form.community = "选择社群";
        this.form.partakeNums = 1;
      }
      this.typeShow = false;
    },
    clickTypeOverlay() {
      this.typeShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  // box-sizing: border-box;
  min-height: 100vh;
  background-color: #ffffff;
  position: relative;
  &.isSwitchOld {
    .popup_box {
      .bottom_box {
        .no_more {
          font-size: 42px;
        }

        .item {
          font-size: 46px;
        }
      }

      .input_box {
        input {
          font-size: 46px;
        }
      }
    }

    .btnBox {
      .btn {
        font-size: 46px;
      }
    }

    .van-cell {
      font-size: 46px;
    }

    .explain {
      font-size: 46px;
    }

    .box {
      .inBox {
        .numBox {
          .num {
            font-size: 54px;
          }
        }

        .text {
          font-size: 46px;
        }

        .type {
          font-size: 46px;
        }
      }
    }
  }

  .popup_box {
    width: 100%;
    height: 100%;

    .bottom_box {
      width: 100%;
      height: calc(100% - 236px);
      overflow: hidden;
      overflow-y: auto;

      .no_more {
        width: 100%;
        height: 132px;
        line-height: 132px;
        text-align: center;
        font-size: 28px;
        color: #a8a9ab;
        background-color: #f7f7f7;
      }

      .item {
        width: 100%;
        height: 132px;
        line-height: 132px;
        padding-left: 142px;
        box-sizing: border-box;
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        font-size: 32px;
        font-weight: 400;
        color: #333333;
      }

      .active {
        background-color: rgba(0, 0, 0, 0.06);
      }
    }

    .input_box {
      width: 686px;
      height: 82px;
      border-radius: 8px;
      margin: 0 auto;
      margin-bottom: 32px;
      overflow: hidden;

      input {
        width: 100%;
        height: 100%;
        background: #f5f5f5;
        font-size: 32px;
        color: #ccc;
        text-indent: 0.5em;
      }

      input::-webkit-input-placeholder {
        color: #cccccc;
      }
    }

    .top_box {
      width: 100%;
      height: 122px;
      box-sizing: border-box;
      position: relative;

      .cancel {
        position: absolute;
        top: 50%;
        left: 32px;
        transform: translateY(-50%);
        font-size: 36px;
        color: #333333;
      }

      .complete {
        position: absolute;
        top: 50%;
        right: 32px;
        transform: translateY(-50%);
        font-size: 36px;
        color: #007eff;
      }
    }
  }

  .btnBox {
    width: 100%;
    // margin-top: 500px;
    // position: absolute;
    // left: 0;
    // bottom: 100px;
    position: absolute;
    bottom: 100px;

    .btn {
      width: 686px;
      height: 96px;
      background: #007eff;
      border-radius: 48px;
      line-height: 96px;
      text-align: center;
      margin: 0 auto;
      font-size: 32px;
      font-weight: 600;
      color: #ffffff;
    }
  }

  .van-cell {
    width: 686px;
    background: #f7f8fa;
    border-radius: 16px;
    margin: 0 auto;
    margin-bottom: 15px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #323334;

    .van-field__word-limit {
      color: #cbcccd !important;
    }
  }

  .explain {
    width: 100%;
    height: 120px;
    font-size: 32px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    padding: 44px 0 0 32px;
    box-sizing: border-box;
  }

  .box {
    width: 100%;
    height: 108px;
    position: relative;

    .line {
      width: calc(100% - 32px);
      height: 1px;
      background: rgba(0, 0, 0, 0.06);
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .inBox {
      width: 100%;
      height: 108px;
      position: relative;
      box-sizing: border-box;

      .numBox {
        position: absolute;
        top: 50%;
        right: 28px;
        transform: translateY(-50%);
        display: flex;
        align-items: center;

        .num {
          display: inline-block;
          margin: 0 14px 0 18px;
          font-size: 40px;
          font-weight: 400;
          color: #151c39;
        }

        .sub,
        .add {
          width: 50px;
          height: 100%;
        }
      }

      .arrow {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        right: 28px;
        transform: translateY(-50%);
      }

      .text {
        position: absolute;
        top: 50%;
        right: 68px;
        transform: translateY(-50%);
        font-size: 32px;
        font-weight: 400;
        color: #333333;
      }

      .type {
        position: absolute;
        top: 50%;
        left: 32px;
        transform: translateY(-50%);
        font-size: 32px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}
</style>
